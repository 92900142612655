/* App Layout and Background Image */
.App {
  background-color: #282c34;
  color: white;
}

.App-background {
  background-image: url('../img/liquid-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App-container {
  padding: 24px;
}

.App-display {
  padding: 0 3em;
}

/* App Text */

.App-title-margin {
  margin: 0.5em 0 0.75em;
}

.App-body-margin {
  margin: 0.5em 0;
}

.App-header-margin {
  margin: 0.5em 0 0;
}

.App-no-margin {
  margin: 0;
}

.App-top-margin {
  margin: 0.5em 0 0;
}

.App-bottom-margin {
  margin: 0em 0 0.5em;
}

.App-tagline-margin {
  margin: 1em 1em 0.25em;
}

.App-link {
  color:#61dafb;
}

/* App Images and Layout */
.App-brand {
  height: 40vmin;
  pointer-events: none;
}

.App-logo {
  height: 24px;
  text-align: center;
  text-justify: auto;
  margin: 0em;
}

.App-logo-group {
  margin: 0em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Buttons and Button Group */
.App-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-icon-button {
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.125em 0.125em;
  margin: 0em 0.25em;
  font-size: 32px;
}

.App-hoverable {
  transition-duration: 0.4s;  
}

.App-hoverable:hover {
  opacity: 0.7;
}

.App-footer-group {
  margin: 1em;
}
